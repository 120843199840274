/* Search */

$(document).ready(function () {
  $("#search .src-btn").click(function (e) {
    e.preventDefault();
    $(this).closest('#search').addClass("active");
    $('header .tab-block').css("display", "none");
  });

  $("#search .close-btn").click(function (e) {
    e.preventDefault();
    $('#search').removeClass("active");
    $('header .tab-block').css("display", "inherit");
  });

  $('[data-toggle="tooltip"]').tooltip();
  var acc = document.getElementsByClassName("section-head");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].onclick = function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    }
  }
});

function openNav() {
  document.getElementById("sidenav").style.left = "0px";
}
function closeNav() {
  document.getElementById("sidenav").style.left = "-250px";
}





